import React from 'react';
import Layout from '../components/organisms/Layout/Layout';
import Seo from "../components/organisms/Seo/Seo";
import Container from '../components/atoms/Container/Container';
import * as styles from './about.module.css';
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
import { graphql } from 'gatsby';
import Slider from '../components/atoms/Slider/Slider';

const AboutContentful = ({ data }) => {
    const page = (data && data.aboutPage) || false;
    const seoTitle = page.seoTitle || page.pageName;
    const carouselSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        centerMode: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }

    const membershipsImages = page.blocks[2].blocks.filter(block => !!block.desktopImage)

    return (
        <Layout genClass={styles.aboutMainWrapper}>
            <Seo title={seoTitle} description={page.metaDescription} image={page.metaPreviewImage ? `https:${page.metaPreviewImage.file.url}` : ''}/>

                {page && (
                    <div className={styles.aboutFullWidthImageSection}
                         style={{
                             backgroundImage: `url(${page.blocks[0].blocks[0].desktopImage.file.url})`
                         }}
                    >
                        <Container size={"medium"} class>
                            <div className={styles.aboutBreadcrumbs}>
                                <Breadcrumbs crumbs={[{ link: '/', label: 'Home' }, { label: 'Welcome to LAVO' }]} isSpacerWhite={true}/>
                            </div>
                            <div className={styles.aboutTopSectionLeft}>
                                <div className={styles.aboutPageTitle}>{page.pageTitle}</div>
                                <div className={styles.aboutMetaTitle}>{page.metaTitle}</div>
                            </div>
                        </Container>
                    </div>
                )}
            {page && (
                <>
                    <div className={styles.aboutTopSectionRight}>
                        <img src={page.blocks[0].blocks[1].desktopImage.file.url} alt="" />
                    </div>
                    <div className={styles.aboutMainContentContainer}>
                        <Container size={'medium'}>
                            <div className={styles.aboutMainContentWrapper}>
                                <div className={styles.aboutMainContentLeft}>
                                    <div className={styles.aboutMainContentLeftHead}>
                                        {page.blocks[0].blocks[2].heading}
                                    </div>
                                    <div
                                      className={styles.aboutMainContentText}
                                      dangerouslySetInnerHTML={{
                                          __html: page.blocks[0].blocks[2].content.childMarkdownRemark.html
                                      }}
                                    >
                                    </div>
                                </div>
                                <div className={styles.aboutMainContentRight}>
                                    <div
                                      className={styles.aboutMainContentText}
                                      dangerouslySetInnerHTML={{
                                          __html: page.blocks[0].blocks[3].content.childMarkdownRemark.html
                                      }}
                                    >
                                    </div>
                                    <p></p>
                                    <div className={styles.aboutMainContentRightFooter}>
                                        {page.blocks[0].blocks[3].heading}
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className={styles.aboutRecognitionSection}>
                        <Container size={'medium'}>
                            <div className={styles.aboutRecognitionLeft}>
                                <img src={page.blocks[0].blocks[4].contentLeft.backgroundImage.desktopImage.file.url} alt='' />
                            </div>
                            <div className={styles.aboutRecognitionRight}>
                                <div className={styles.aboutRecognitionHeader}>
                                    {page.blocks[0].blocks[4].contentRight.heading}
                                </div>
                                <div
                                  className={styles.aboutRecognitionContent}
                                  dangerouslySetInnerHTML={{
                                      __html: page.blocks[0].blocks[4].contentRight.childContentfulBlockContentContentTextNode.childMarkdownRemark.html
                                  }}
                                >
                                </div>
                            </div>
                        </Container>
                    </div>

                    <div className={styles.aboutAwardsSection}>
                        <div className={styles.aboutAwardsHeader}>{page.blocks[1].name}</div>
                        <div className={styles.aboutAwardsCarouselWrapper}>
                            <Slider {...carouselSettings}>
                                {page.blocks[1].blocks.map(block => (
                                    <div><div className={styles.aboutAwardItemWrapper}>
                                        <img className={styles.aboutAwardItemImage} src={block.desktopImage.file.url} alt=""/>
                                    </div></div>
                                ))}
                            </Slider>
                        </div>
                    </div>

                    <div className={styles.aboutMembershipsSection}>
                        <div className={styles.aboutMembershipsTextWrapper}>
                            <div className={styles.aboutMembershipsHeader}>{page.blocks[2].name}</div>
                            <div
                                className={styles.aboutMembershipsMetaDescription}
                                dangerouslySetInnerHTML={{
                                    __html: page.blocks[2].blocks[0].content.childMarkdownRemark.html
                                }}
                            >
                            </div>
                        </div>

                        <div className={styles.aboutMembershipsWrapper}>
                        {membershipsImages.map(membershipImageBlock => (
                            <div className={styles.aboutMembershipsItemImageWrapper}>
                                <img className={styles.aboutMembershipsItemImage} src={membershipImageBlock.desktopImage.file.url} alt=""/>
                            </div>
                        ))}
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
}

export default AboutContentful;

export const query = graphql`
query ContentfulPageAbout {
    aboutPage: contentfulPage(pageName: {eq: "About Lavo"}) {
        pageName
        pageTitle
        metaCanonicalLink
        metaDescription
        metaPreviewImage{
          file {
            url
          }
        }
        seoTitle
        metaTitle
        blocks {
            name
            blocks {
                ... on ContentfulBlockImage {
                    desktopImage {
                        gatsbyImageData
                        file {
                            url
                        }
                    }
                    mobileImage {
                        gatsbyImageData
                        file {
                            url
                        }
                    }
                }
                ... on ContentfulBlockContent {
                    heading
                    content {
                        childMarkdownRemark {
                            html
                        }
                    }
                    backgroundImage {
                        desktopImage {
                            file {
                                url
                            }
                        }
                    }
                }
                ... on ContentfulBlockDoubleCards {
                  name
                  internal {
                    type
                  }
                  title
                  backgroundColour
                  textColour {
                    textColour {
                      textColour
                    }
                  }
                  backgroundImage {
                    altText
                    imageMaxWidth
                    desktopImage {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                    mobileImage {
                      gatsbyImageData
                      file {
                        url
                      }
                    }
                  }
                  blocks {
                    alignment {
                      alignment
                    }
                    blockWidth {
                      blockWidth
                    }
                    backgroundColour
                  }
                  blockWidth {
                    blockWidth {
                      blockWidth
                    }
                  }
                  paddingTopBottom {
                    paddingTopBottom {
                      paddingTopBottom
                    }
                  }
                  blockAlignment {
                    blockAlignment
                  }
                  mobileRevert
                  contentLeft {
                    heading
                    childContentfulBlockContentContentTextNode {
                      childMarkdownRemark {
                        html
                      }
                    }
                    buttonName
                    buttonUrl
                    buttonStyle {
                      buttonStyle
                    }
                    name
                    blockWidth {
                      blockWidth {
                        blockWidth
                      }
                    }
                    blockAlignment {
                      blockAlignment
                    }
                    backgroundImage {
                      altText
                      imageMaxWidth
                      desktopImage {
                        gatsbyImageData
                        file {
                          url
                        }
                      }
                      mobileImage {
                        gatsbyImageData
                        file {
                          url
                        }
                      }
                    }
                    headingSize {
                      headingSize
                    }
                    textAlignment {
                      textAlignment
                    }
                    textColour
                    imagePosition {
                      imagePosition
                    }
                    imageHoverAnimation {
                      imageHoverAnimation
                    }
                    backgroundColour
                  }
                  contentRight {
                    heading
                    childContentfulBlockContentContentTextNode {
                      childMarkdownRemark {
                        html
                      }
                    }
                    buttonName
                    buttonUrl
                    buttonStyle {
                      buttonStyle
                    }
                    name
                    blockWidth {
                      blockWidth {
                        blockWidth
                      }
                    }
                    blockAlignment {
                      blockAlignment
                    }
                    backgroundImage {
                      altText
                      imageMaxWidth
                      desktopImage {
                        gatsbyImageData
                        file {
                          url
                        }
                      }
                      mobileImage {
                        gatsbyImageData
                        file {
                          url
                        }
                      }
                    }
                    headingSize {
                      headingSize
                    }
                    textAlignment {
                      textAlignment
                    }
                    textColour
                    imagePosition {
                      imagePosition
                    }
                    imageHoverAnimation {
                      imageHoverAnimation
                    }
                    backgroundColour
                  }
                }
            }
        }
    }
}
`